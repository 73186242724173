import { useTranslation } from 'react-i18next'
import { Box, Link, Typography } from '@mui/material'

const FreePlanBar = () => {
    const { t } = useTranslation()

    return (
        <Box
            alignItems="center"
            data-testid="free-plan-bar"
            display="flex"
            gap={2}
            ml={4}
        >
            <Typography
                color="error"
                data-testid="free-plan-bar-title"
                variant="body1"
            >
                {t('You are using a free version of ToolBox. Check out our')}{' '}
                <Link
                    color="secondary"
                    data-testid="free-plan-bar-link"
                    href="https://tempustools.com/pricing/"
                    target="_blank"
                >
                    {t('plans and features')}
                </Link>
            </Typography>
        </Box>
    )
}

export default FreePlanBar
