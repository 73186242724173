import { createSlice } from '@reduxjs/toolkit'

import { webStoreAuthApi } from '@/app/services/web-store/webStoreAuth'

import { deleteCookie, getCookie, setCookie } from '../../../features/web-store/helpers/auth/cookie'
import { webStoreCustomerApi } from '../../services/web-store/webStoreCustomer'

const isContactLoggedInCookie = getCookie('isContactLoggedIn') ? JSON.parse(getCookie('isContactLoggedIn')) : false
const isGuestLoggedInCookie = getCookie('isGuestLoggedIn') ? JSON.parse(getCookie('isGuestLoggedIn')) : false
const currentContactCookie = getCookie('currentContact') ? JSON.parse(getCookie('currentContact')) : null
const currentCustomerCookie = getCookie('currentCustomer') ? JSON.parse(getCookie('currentCustomer')) : null
const guestUserDataCookie = getCookie('guestUserData') ? JSON.parse(getCookie('guestUserData')) : null

const initialState = {
    isContactLoggedIn: isContactLoggedInCookie,
    isGuestLoggedIn: isGuestLoggedInCookie,
    contact: currentContactCookie,
    customer: currentCustomerCookie,
    guest: guestUserDataCookie,
    token: null,
}

const webStoreAuthSlice = createSlice({
    name: 'webStoreAuth',
    initialState,
    reducers: {
        unsetCurrentUser: (state) => {
            state.contact = null
            state.customer = null
            state.guest = null
            state.isContactLoggedIn = false
            state.isGuestLoggedIn = false
            deleteCookie('isContactLoggedIn')
            deleteCookie('isGuestLoggedIn')
            deleteCookie('currentContact')
            deleteCookie('currentCustomer')
            deleteCookie('guestUserData')
        },
        setGuestUserLoggin: (state) => {
            state.isGuestLoggedIn = true
            setCookie('isGuestLoggedIn', 'true', {
                samesite: 'strict',
                'max-age': 3600,
            })
        },
        setGuestUserData: (state, action) => {
            state.guest = action.payload
            setCookie('guestUserData', JSON.stringify(action.payload), {
                samesite: 'strict',
                'max-age': 3600,
            })
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(webStoreAuthApi.endpoints.validateCode.matchFulfilled, (state, { payload }) => {
                state.contact = payload
                state.isContactLoggedIn = true
                state.isGuestLoggedIn = false
                state.guest = null
                setCookie('isContactLoggedIn', 'true', {
                    samesite: 'strict',
                    'max-age': 3600,
                })
                setCookie('currentContact', JSON.stringify(payload), {
                    samesite: 'strict',
                    'max-age': 3600,
                })
                deleteCookie('isGuestLoggedIn')
                deleteCookie('guestUserData')
            })
            .addMatcher(webStoreCustomerApi.endpoints.getCustomer.matchFulfilled, (state, { payload }) => {
                state.customer = payload
                const cookieData = structuredClone(payload)
                delete cookieData.addresses

                setCookie('currentCustomer', JSON.stringify(cookieData), {
                    samesite: 'strict',
                    'max-age': 3600,
                })
            })
    },
})

export const selectCurrentContact = (state) => state.webStoreAuth.contact
export const selectCurrentCustomer = (state) => state.webStoreAuth.customer
export const selectGuestUser = (state) => state.webStoreAuth.guest
export const selectIsContactLoggedIn = (state) => state.webStoreAuth.isContactLoggedIn
export const selectIsGuestLoggedIn = (state) => state.webStoreAuth.isGuestLoggedIn

export const { setGuestUserData, setGuestUserLoggin, unsetCurrentUser } = webStoreAuthSlice.actions

export default webStoreAuthSlice.reducer
