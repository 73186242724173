import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Link,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useSnackbar } from 'notistack'

import { useCreateStripeAccountMutation, useLazyGetStripeOnboardingLinkQuery } from '@/app/services/integrations'
import { useUpdateOrganisationMutation } from '@/app/services/organisation'
import { selectOrganisation, selectOrganisationId, setOrganisation } from '@/app/slices/organisationSlice'

const classes = {
    actionPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        zIndex: 500,
        width: '100%',
    },
    addButton: {
        width: 40,
        height: 40,
    },

    remainingUsersLabel: {
        padding: '1.5rem',
        fontWeight: 500,
        flex: 1,
    },

    tableContainer: {
        background: (theme) => theme.palette.background.paper,
        border: (theme) => `1px solid ${theme.palette.grey[400]}`,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    table: {
        tableLayout: 'fixed',
        boxSizing: 'border-box',
        borderCollapse: 'separate',
    },
    headerTableCell: {
        paddingBlock: 2,
        paddingInline: 2,
        verticalAlign: 'bottom',
    },
    tableRow: {
        '&:hover': {
            background: (theme) => theme.palette.action.hover,
        },
    },
    tableCell: {
        paddingBlock: 2,
        paddingInline: 2,
    },
    tableCellWithEllipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    tableFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    iconColor: {
        color: 'secondary.main',
    },
    disabledIcon: (isDeleted) => ({
        color: isDeleted ? 'text.disabled' : 'text.primary',
    }),
}

const Payments = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const [isLoading, setIsLoading] = useState(false)

    const [createStripeAccount, { isLoading: isCreatingAccount }] = useCreateStripeAccountMutation()
    const [getOnboardingLink, { isLoading: isGettingLink }] = useLazyGetStripeOnboardingLinkQuery()
    const [updateOrganisation] = useUpdateOrganisationMutation()

    const isStripeAccountConnected = Boolean(organisation?.stripeConnectedAccountId)

    const {
        paymentAccountErrors,
        paymentChargesEnabled,
        paymentDetailsSubmitted,
        paymentPayoutsEnabled,
        paymentsEnabledInternal,
        paymentsEnabledWebStore,
    } = organisation

    const handleCreateStripeAccount = async () => {
        try {
            const onBoardingLink = await createStripeAccount({ organisationId }).unwrap()
            enqueueSnackbar(t('Stripe account created successfully.'), {
                variant: 'success',
                onExited: () => {
                    window.open(onBoardingLink, '_self')
                },
            })
        } catch (error) {
            enqueueSnackbar(t('An error occurred creating your Stripe account. Please try again later.'), {
                variant: 'error',
            })
        }
    }

    const handleResumeAccountCreation = async () => {
        try {
            const onBoardingLink = await getOnboardingLink({ organisationId }).unwrap()

            enqueueSnackbar(t('Resuming Stripe account creation...'), {
                variant: 'info',
                onExited: () => {
                    window.open(onBoardingLink, '_self')
                },
            })
        } catch (error) {
            enqueueSnackbar(t('An error occurred resuming your Stripe account creation. Please try again later.'), {
                variant: 'error',
            })
        }
    }

    const updatePaymentsEnabledOption = async (event) => {
        const currentOrganisation = organisation
        const updatedOrganisation = {
            ...currentOrganisation,
            paymentsEnabledInternal:
                event.target.name === 'paymentsEnabledInternal'
                    ? event.target.checked
                    : currentOrganisation.paymentsEnabledInternal,
            paymentsEnabledWebStore:
                event.target.name === 'paymentsEnabledWebStore'
                    ? event.target.checked
                    : currentOrganisation.paymentsEnabledWebStore,
        }

        dispatch(setOrganisation(updatedOrganisation))

        try {
            await updateOrganisation({
                organisationDto: {
                    organisationId,
                    paymentsEnabledInternal:
                        event.target.name === 'paymentsEnabledInternal'
                            ? event.target.checked
                            : paymentsEnabledInternal,
                    paymentsEnabledWebStore:
                        event.target.name === 'paymentsEnabledWebStore'
                            ? event.target.checked
                            : paymentsEnabledWebStore,
                },
            }).unwrap()

            enqueueSnackbar(t('Payment options updated successfully.'), {
                variant: 'success',
            })
        } catch (error) {
            dispatch(setOrganisation(currentOrganisation))

            enqueueSnackbar(t('An error occurred updating payment options. Please try again later.'), {
                variant: 'error',
            })
        }
    }

    return (
        <Grid
            key={`organisationId-${organisationId}-payments`}
            px={2}
            spacing={3}
            container
        >
            <Grid xs={12}>
                <Typography
                    data-testid="organisation-payments-setting"
                    id="payments"
                    mb={1}
                    sx={classes.title}
                    variant="h6"
                >
                    {t('Payments')}
                </Typography>

                <Typography variant="body2">
                    {t(
                        'We use Stripe to collect payments from your customers. Stripe is a third party payment processing service that is responsible for all financial transactions that occur between you, your customers and ToolBox.'
                    )}
                </Typography>
                <Typography variant="body2">
                    {t('To enable a checkout experience, a Stripe account needs to be configured correctly.')}
                </Typography>
            </Grid>

            {isLoading ? (
                <Grid xs={12}>
                    <Box
                        display="flex"
                        gap={1}
                        justifyContent="center"
                        marginBlock={2}
                    >
                        <CircularProgress
                            color="secondary"
                            size={21}
                        />
                        <Typography variant="body2">{t('Loading payment status...')}</Typography>
                    </Box>
                </Grid>
            ) : (
                <>
                    <Grid xs={12}>
                        <Typography
                            mb={3}
                            variant="strong1"
                        >
                            {t('Stripe account status')}
                        </Typography>

                        <TableContainer sx={classes.tableContainer}>
                            <Table sx={classes.table}>
                                <colgroup>
                                    <col style={{ width: '160px' }} />
                                    <col style={{ width: '160px' }} />
                                    <col style={{ width: '260px' }} />
                                </colgroup>

                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell sx={classes.headerTableCell}>{t('Status')}</TableCell>
                                        <TableCell sx={classes.headerTableCell}>{t('Action')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!isStripeAccountConnected ? (
                                        <TableRow
                                            data-testid="stripe-account-status-inactive"
                                            key="stripe-account-status-inactive"
                                            sx={classes.tableRow}
                                        >
                                            <TableCell sx={[classes.tableCell, classes.tableCellWithEllipsis]}>
                                                {t('Account')}
                                            </TableCell>

                                            <TableCell sx={classes.tableCell}>{t('No account added')}</TableCell>

                                            <TableCell sx={classes.tableCell}>
                                                <Button
                                                    disabled={isCreatingAccount}
                                                    sx={{ textTransform: 'none' }}
                                                    type="button"
                                                    variant="text"
                                                    onClick={handleCreateStripeAccount}
                                                >
                                                    {isCreatingAccount
                                                        ? t('Creating account...')
                                                        : t('Create Stripe account')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}

                                    {isStripeAccountConnected && !paymentDetailsSubmitted ? (
                                        <TableRow
                                            data-testid="stripe-account-status-in-progress"
                                            key="stripe-account-status-in-progress"
                                            sx={classes.tableRow}
                                        >
                                            <TableCell sx={[classes.tableCell, classes.tableCellWithEllipsis]}>
                                                {t('Account')}
                                            </TableCell>

                                            <TableCell sx={classes.tableCell}>{t('In progress')}</TableCell>

                                            <TableCell sx={classes.tableCell}>
                                                <Button
                                                    disabled={isGettingLink}
                                                    sx={{ textTransform: 'none' }}
                                                    type="button"
                                                    variant="text"
                                                    onClick={handleResumeAccountCreation}
                                                >
                                                    {isGettingLink
                                                        ? t('Resuming account creation...')
                                                        : t('Resume account creation')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}

                                    {isStripeAccountConnected &&
                                    paymentDetailsSubmitted &&
                                    (!paymentChargesEnabled || !paymentPayoutsEnabled || paymentAccountErrors) ? (
                                        <>
                                            <TableRow
                                                data-testid="stripe-account-status-incomplete"
                                                key="stripe-account-status-incomplete"
                                                sx={classes.tableRow}
                                            >
                                                <TableCell sx={[classes.tableCell, classes.tableCellWithEllipsis]}>
                                                    {t('Account')}
                                                </TableCell>

                                                <TableCell sx={[classes.tableCell, { typography: 'strong2' }]}>
                                                    {t('Completed')}
                                                </TableCell>

                                                <TableCell sx={classes.tableCell} />
                                            </TableRow>

                                            <TableRow
                                                data-testid="stripe-account-status-process-payments-incomplete"
                                                key="stripe-account-status-process-payments-incomplete"
                                                sx={classes.tableRow}
                                            >
                                                <TableCell sx={[classes.tableCell, classes.tableCellWithEllipsis]}>
                                                    {t('Process payments')}
                                                </TableCell>

                                                <TableCell
                                                    sx={[
                                                        classes.tableCell,
                                                        {
                                                            typography: paymentChargesEnabled ? 'strong2' : 'body2',
                                                        },
                                                    ]}
                                                >
                                                    {paymentChargesEnabled ? t('Completed') : t('Incomplete')}
                                                </TableCell>

                                                <TableCell sx={classes.tableCell}>
                                                    {!paymentChargesEnabled ? (
                                                        <Link
                                                            href="https://dashboard.stripe.com/account/status"
                                                            rel="noopener"
                                                            target="_blank"
                                                        >
                                                            {t('Review requirements')}
                                                        </Link>
                                                    ) : null}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow
                                                data-testid="stripe-account-status-receive-payouts-incomplete"
                                                key="stripe-account-status-receive-payouts-incomplete"
                                                sx={classes.tableRow}
                                            >
                                                <TableCell sx={[classes.tableCell, classes.tableCellWithEllipsis]}>
                                                    {t('Receive payouts')}
                                                </TableCell>

                                                <TableCell
                                                    sx={[
                                                        classes.tableCell,
                                                        {
                                                            typography: paymentPayoutsEnabled ? 'strong2' : 'body2',
                                                        },
                                                    ]}
                                                >
                                                    {paymentPayoutsEnabled ? t('Completed') : t('Incomplete')}
                                                </TableCell>

                                                <TableCell sx={classes.tableCell}>
                                                    {!paymentPayoutsEnabled ? (
                                                        <Link
                                                            href="https://dashboard.stripe.com/account/status"
                                                            rel="noopener"
                                                            target="_blank"
                                                        >
                                                            {t('Review requirements')}
                                                        </Link>
                                                    ) : null}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow
                                                data-testid="stripe-account-status-other-validations-incomplete"
                                                key="stripe-account-status-other-validations-incomplete"
                                                sx={classes.tableRow}
                                            >
                                                <TableCell sx={[classes.tableCell, classes.tableCellWithEllipsis]}>
                                                    {t('Other validations')}
                                                </TableCell>

                                                <TableCell
                                                    sx={[
                                                        classes.tableCell,
                                                        {
                                                            typography: !paymentAccountErrors ? 'strong2' : 'body2',
                                                        },
                                                    ]}
                                                >
                                                    {paymentAccountErrors ? t('Incomplete') : t('Completed')}
                                                </TableCell>

                                                <TableCell sx={classes.tableCell}>
                                                    {paymentAccountErrors ? (
                                                        <Link
                                                            href="https://dashboard.stripe.com/account/status"
                                                            rel="noopener"
                                                            target="_blank"
                                                        >
                                                            {t('Review requirements')}
                                                        </Link>
                                                    ) : null}
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ) : null}

                                    {isStripeAccountConnected &&
                                    paymentChargesEnabled &&
                                    paymentPayoutsEnabled &&
                                    !paymentAccountErrors ? (
                                        <TableRow
                                            data-testid="stripe-account-status-active-completed"
                                            key="stripe-account-status-active-completed"
                                            sx={classes.tableRow}
                                        >
                                            <TableCell sx={[classes.tableCell, classes.tableCellWithEllipsis]}>
                                                {t('Account')}
                                            </TableCell>

                                            <TableCell sx={[classes.tableCell, { typography: 'strong2' }]}>
                                                {t('Active')}
                                            </TableCell>

                                            <TableCell sx={classes.tableCell}>
                                                <Link
                                                    href="https://dashboard.stripe.com/settings"
                                                    rel="noopener"
                                                    target="_blank"
                                                >
                                                    {t('Stripe settings')}
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    {paymentDetailsSubmitted ? (
                        <Grid xs={12}>
                            <Typography
                                mb={3}
                                variant="strong1"
                            >
                                {t('Payment options')}
                            </Typography>

                            <Box>
                                <FormControlLabel
                                    checked={paymentsEnabledInternal}
                                    control={<Switch />}
                                    data-testid="payments-options-internal-quotes"
                                    label={t('Payment buttons show on document pages and PDFs')}
                                    labelPlacement="start"
                                    name="paymentsEnabledInternal"
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            color: 'text.secondary',
                                        },
                                    }}
                                    sx={{ m: 0, justifyContent: 'space-between', width: 1 }}
                                    onChange={updatePaymentsEnabledOption}
                                />

                                <FormControlLabel
                                    checked={paymentsEnabledWebStore}
                                    control={<Switch />}
                                    data-testid="payments-options-web-store"
                                    label={t('Payment checkout page is enabled for the Web Store')}
                                    labelPlacement="start"
                                    name="paymentsEnabledWebStore"
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            color: 'text.secondary',
                                        },
                                    }}
                                    sx={{ m: 0, justifyContent: 'space-between', width: 1 }}
                                    onChange={updatePaymentsEnabledOption}
                                />
                            </Box>
                        </Grid>
                    ) : null}
                </>
            )}
        </Grid>
    )
}

export default Payments
