import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { CheckCircleOutlineRounded } from '@mui/icons-material'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import { green } from '@mui/material/colors'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { selectStoreSettings } from '@/app/services/organisation'
import { selectMaterials } from '@/app/services/web-store/webStoreMaterial'
import { selectQuote, useGetQuoteQuery } from '@/app/services/web-store/webStoreQuote'
import { selectQuoteItems, useGetQuoteItemsQuery } from '@/app/services/web-store/webStoreQuoteItems'
import {
    selectCurrentContact,
    selectIsContactLoggedIn,
    selectIsGuestLoggedIn,
} from '@/app/slices/web-store/webStoreAuthSlice'
import TbxShadowScroll from '@/common/components/TbxShadowScroll'
import { getAddressDetails } from '@/common/helpers/addressUtilities'
import { getLargestDimensions, QUOTE_USER_TYPE, WEB_STORE_QUOTE_STATUS } from '@/common/utils'

import OrderedActions from '../../components/Order/OrderedActions'
import { formatCurrency, formatNumber } from '../../helpers/utilities'

const classes = {
    root: {
        backgroundColor: 'background.paper',
        borderRadius: 1,
        border: (theme) => `1px ${theme.palette.grey[300]} solid`,
        width: '100%',
    },
    successMessageContainer: {
        gap: 3,
        maxWidth: '500px',
        textAlign: 'center',
        marginBottom: 6,
    },
    needHelp: {
        maxWidth: '500px',
    },

    orderSummary: (theme) => ({
        padding: 3,
        gap: 3,
        overflow: 'hidden',
        [theme.breakpoints.up('lg')]: {
            height: 'calc(100vh - 114px)',
        },
    }),
    summaryTitle: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    orderInfo: {
        gap: 1.5,
    },
    partsListContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    partsList: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    },
    partInfo: {
        gap: 0.75,
    },
    partDetailsInfo: {
        gap: 1.5,
    },
    totalInfo: {
        gap: 1.5,
    },
    smallText: {
        fontSize: '0.75rem',
    },
    strongText: {
        fontWeight: 700,
    },
}

const OrderConfirmation = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { organisationId, quoteId } = useParams()

    const { isError, isLoading, isSuccess } = useGetQuoteQuery({
        organisationId,
        quoteId,
    })
    const { isLoading: isLoadingItems } = useGetQuoteItemsQuery({
        organisationId,
        quoteId,
    })

    const quote = useSelector((state) => selectQuote(state, { organisationId, quoteId }))
    const quoteItems = useSelector((state) => selectQuoteItems(state, { organisationId, quoteId }))
    const materials = useSelector((state) => selectMaterials(state, { organisationId }))

    const isContactLoggedIn = useSelector(selectIsContactLoggedIn)
    const isGuestLoggedIn = useSelector(selectIsGuestLoggedIn)
    const currentUser = useSelector(selectCurrentContact)
    const storeSettings = useSelector((state) => selectStoreSettings(state, { organisationId }))

    const quoteItemMaterial = (quoteItem) => materials.find((material) => material.materialId === quoteItem.materialId)

    const lengthUnit = storeSettings.defaultDrawingUnits === 'Metric' ? 'mm' : 'in'
    const weightUnit = storeSettings.defaultDrawingUnits === 'Metric' ? 'kg' : 'lb'

    const largestDimensions = getLargestDimensions(quoteItems, storeSettings)

    const totalWithoutTax = quote.linePrice

    const quoteMinimumChargePrice = quote.minimumQuoteChargePrice || 0

    const deliveryPrice = quote.deliveryPrice || 0

    const subTotalWithDiff = totalWithoutTax + quoteMinimumChargePrice

    const total = quote?.lineTaxedPrice

    const totalTax = total - subTotalWithDiff

    const quoteTaxRate = quote?.taxRateId ? quote?.taxRate : totalTax / quote?.linePrice

    useEffect(() => {
        if (!storeSettings.webStoreIsPublic && !isContactLoggedIn) {
            navigate(`/store/${organisationId}/login`)
        }

        if (!isGuestLoggedIn && !isContactLoggedIn) {
            navigate(`/store/${organisationId}/login`)
        }
    }, [isContactLoggedIn, isGuestLoggedIn, storeSettings])

    useEffect(() => {
        if (
            isSuccess &&
            quote?.customerContactId &&
            quote?.quoteUserType === QUOTE_USER_TYPE.Customer &&
            !isContactLoggedIn
        ) {
            navigate(`/store/${organisationId}/login`)
        }

        if (
            isSuccess &&
            quote?.customerContactId &&
            quote?.quoteUserType === QUOTE_USER_TYPE.Customer &&
            isContactLoggedIn &&
            currentUser.contactId !== quote.customerContactId
        ) {
            navigate(`/store/${organisationId}`)
        }
    }, [isSuccess, quote, isContactLoggedIn])

    if ((!isLoading && quote && Object.keys(quote).length === 0) || isError) {
        return <Navigate to={`/store/${organisationId}/not-found`} />
    }

    if (!isLoading && isSuccess && quote.status !== WEB_STORE_QUOTE_STATUS.PendingOrderConfirmation) {
        return <Navigate to={`/store/${organisationId}/${quoteId}/checkout`} />
    }

    if (!isLoading && isSuccess && quote.status === WEB_STORE_QUOTE_STATUS.PendingOrderConfirmation) {
        return (
            <>
                <OrderedActions />
                <Grid
                    component="section"
                    lg={7}
                    xs={12}
                    container
                    item
                >
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        p={6}
                        sx={classes.root}
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            sx={classes.successMessageContainer}
                        >
                            <CheckCircleOutlineRounded style={{ fontSize: '88px', color: green[600] }} />
                            <Typography variant="h5">{t('Thank you for your order')}</Typography>
                            <Typography variant="body1">
                                {t(
                                    'Hi {{contact}}, an order confirmation has been emailed to your supplied email address.',
                                    { contact: quote.customerContact.name }
                                )}
                            </Typography>
                            <Typography variant="h6">
                                {t('Order number: {{quoteName}}', { quoteName: quote.name })}
                            </Typography>
                            <Typography variant="body1">
                                {t(
                                    'If we have any questions about your order, we will contact you on your contact number or email address provided.'
                                )}
                            </Typography>
                            {quote?.deliveryOption === 0 ? (
                                <Typography variant="body1">
                                    {t('When the order is ready, pickup is from {{pickupAddress}}', {
                                        pickupAddress: getAddressDetails(storeSettings?.pickupAddress),
                                    })}
                                </Typography>
                            ) : null}

                            {quote?.deliveryOption === 1 && quote?.deliveryPricingMethod === 'SeparateCharge' ? (
                                <Typography variant="body1">
                                    {t('We will calculate the delivery charge and contact you with a quote.')}
                                </Typography>
                            ) : null}

                            {quote?.deliveryOption === 1 && quote?.deliveryPricingMethod === 'PayUponDelivery' ? (
                                <Typography variant="body1">
                                    {t(
                                        'When the order is ready, {{deliveryProvider}} will deliver the order to your nominated address: {{deliveryAddress}}. A delivery fee is payable directly to the delivery provider.',
                                        {
                                            deliveryProvider: quote?.customerDeliveryProvider
                                                ? quote?.customerDeliveryProvider
                                                : quote?.deliveryProvider,
                                            deliveryAddress: getAddressDetails(quote?.deliveryAddress),
                                        }
                                    )}
                                </Typography>
                            ) : null}

                            {quote?.deliveryOption === 1 && quote?.deliveryPricingMethod === 'ChargeToOrder' ? (
                                <Typography variant="body1">
                                    {t(
                                        'When the order is ready, we will deliver the order to your nominated address: {{deliveryAddress}}.',
                                        {
                                            deliveryAddress: getAddressDetails(quote?.deliveryAddress),
                                        }
                                    )}
                                </Typography>
                            ) : null}
                        </Box>

                        {storeSettings.webStoreSupportEmail ? (
                            <Box sx={classes.needHelp}>
                                <Alert
                                    severity="info"
                                    variant="standard"
                                >
                                    <AlertTitle>{t('Have any questions?')}</AlertTitle>
                                    {t('Please contact us at')}{' '}
                                    <a href={`mailto:${storeSettings.webStoreSupportEmail}`}>
                                        {storeSettings.webStoreSupportEmail}
                                    </a>{' '}
                                    {t('from the email address supplied and your order number.')}
                                </Alert>
                            </Box>
                        ) : null}
                    </Box>
                </Grid>

                <Grid
                    component="aside"
                    lg={5}
                    xs={12}
                    container
                    item
                >
                    <Box sx={classes.root}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            sx={classes.orderSummary}
                        >
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography
                                    sx={classes.summaryTitle}
                                    variant="body1"
                                >
                                    {t('Order summary')}
                                </Typography>
                                <Typography
                                    sx={classes.summaryTitle}
                                    variant="body1"
                                >
                                    {quote.name}
                                </Typography>
                            </Box>

                            <Box
                                display="flex"
                                flexDirection="column"
                                sx={classes.orderInfo}
                            >
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body2">{t('Net weight')}</Typography>
                                    <Typography
                                        className="price"
                                        variant="body2"
                                    >
                                        {formatNumber(quote.totalMass, storeSettings.locale)} {weightUnit}
                                    </Typography>
                                </Box>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body2">{t('Largest long-dimension')}</Typography>
                                    <Typography
                                        className="price"
                                        variant="body2"
                                    >
                                        {largestDimensions.long}
                                    </Typography>
                                </Box>
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body2">{t('Largest short-dimension')}</Typography>
                                    <Typography
                                        className="price"
                                        variant="body2"
                                    >
                                        {largestDimensions.short}
                                    </Typography>
                                </Box>
                            </Box>

                            <Divider />

                            <TbxShadowScroll
                                scrollContainerSx={classes.partsList}
                                wrapperSx={classes.partsListContainer}
                            >
                                {!isLoadingItems
                                    ? quoteItems.map((item) => (
                                          <Box
                                              alignItems="end"
                                              className="part"
                                              display="flex"
                                              justifyContent="space-between"
                                              key={item.id}
                                          >
                                              <Box
                                                  display="flex"
                                                  flexDirection="column"
                                                  sx={classes.partInfo}
                                              >
                                                  <Typography
                                                      sx={classes.strongText}
                                                      variant="body1"
                                                  >
                                                      {item.name}
                                                  </Typography>

                                                  <Box
                                                      display="flex"
                                                      flexDirection="row"
                                                      sx={classes.partDetailsInfo}
                                                  >
                                                      <Typography
                                                          sx={classes.smallText}
                                                          variant="body2"
                                                      >
                                                          {t('Material')}: {quoteItemMaterial(item)?.materialName}
                                                      </Typography>
                                                      <Typography
                                                          sx={classes.smallText}
                                                          variant="body2"
                                                      >
                                                          {t('Thickness')}: {item.thickness}
                                                          {lengthUnit}
                                                      </Typography>
                                                      <Typography
                                                          sx={classes.smallText}
                                                          variant="body2"
                                                      >
                                                          {t('Quantity')}: {item.quantity}
                                                      </Typography>
                                                  </Box>
                                              </Box>

                                              <Typography
                                                  sx={classes.strongText}
                                                  variant="body1"
                                              >
                                                  {formatCurrency(
                                                      item.linePrice,
                                                      storeSettings.currencyCode,
                                                      storeSettings.locale
                                                  )}
                                              </Typography>
                                          </Box>
                                      ))
                                    : null}
                            </TbxShadowScroll>

                            <Box
                                display="flex"
                                flexDirection="column"
                                sx={classes.totalInfo}
                            >
                                {quote.minimumQuoteChargePrice ? (
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="body1">{t('Minimum order charge')}</Typography>
                                        <Typography
                                            sx={classes.strongText}
                                            variant="body1"
                                        >
                                            {formatCurrency(
                                                quoteMinimumChargePrice,
                                                storeSettings.currencyCode,
                                                storeSettings.locale
                                            )}
                                        </Typography>
                                    </Box>
                                ) : null}

                                {quote.deliveryPrice ? (
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="body1">{t('Delivery')}</Typography>
                                        <Typography
                                            sx={classes.strongText}
                                            variant="body1"
                                        >
                                            {formatCurrency(
                                                deliveryPrice,
                                                storeSettings.currencyCode,
                                                storeSettings.locale
                                            )}
                                        </Typography>
                                    </Box>
                                ) : null}

                                <Divider />

                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body1">{t('Subtotal')}</Typography>
                                    <Typography
                                        sx={classes.strongText}
                                        variant="body1"
                                    >
                                        {formatCurrency(
                                            subTotalWithDiff,
                                            storeSettings.currencyCode,
                                            storeSettings.locale
                                        )}
                                    </Typography>
                                </Box>

                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="body1">
                                        {t('Taxes')} ({quoteTaxRate}%)
                                    </Typography>
                                    <Typography
                                        sx={classes.strongText}
                                        variant="body1"
                                    >
                                        {formatCurrency(totalTax, storeSettings.currencyCode, storeSettings.locale)}
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box
                                    alignItems="center"
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Typography variant="h6">{t('Total')}</Typography>
                                    <Typography
                                        sx={classes.strongText}
                                        variant="h6"
                                    >
                                        {formatCurrency(total, storeSettings.currencyCode, storeSettings.locale)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </>
        )
    }
}

export default memo(OrderConfirmation)
