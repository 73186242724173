import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowDropDown } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { Box } from '@mui/system'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce'

import { useCalculateQuoteMutation, useUpdateQuoteMutation } from '@/app/services/quotes'
import { selectOrganisation, selectOrganisationId } from '@/app/slices/organisationSlice'
import { setSelectedQuoteStatus } from '@/app/slices/quoteItemsSlice'
import { TbxTooltip } from '@/common/components'
import TbxLocalizationProvider from '@/common/components/TbxLocalizationProvider/TbxLocalizationProvider'
import { useToolBoxTreatments } from '@/common/hooks'
import {
    DELIVERY_PRICING_METHODS,
    QUOTE_PAYMENT_STATUS,
    QUOTE_STATUS,
    SHIPPING_OPTIONS,
} from '@/common/utils/Constants/Constants'

import QuoteShippingBreakdown from './QuoteShippingBreakdown'

const classes = {
    shippingInputs: {
        width: 'calc(100% / 5)',
    },

    disabledInput: {
        '& .Mui-disabled': {
            textFillColor: '#34495E',
        },
        '& .MuiInput-input.Mui-disabled': {
            fontWeight: 'bold',
        },
    },
    divider: {
        borderStyle: 'dashed',
        borderColor: blueGrey[200],
    },
}

const QuoteDetailsOptions = ({ quote }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar()

    const { showShipping } = useToolBoxTreatments()

    const organisation = useSelector(selectOrganisation)
    const organisationId = useSelector(selectOrganisationId)

    const [updateQuote] = useUpdateQuoteMutation()
    const [calculateQuote] = useCalculateQuoteMutation({ fixedCacheKey: 'shared-calculate-quote' })

    const [shippingOption, setShippingOption] = useState(quote?.deliveryOption ?? null)
    const [requireShippingDate, setRequireShippingDate] = useState(quote?.requiredDateUtc ?? null)
    const [deliveryPricingMethod, setDeliveryPricingMethod] = useState(quote?.deliveryPricingMethod ?? null)
    const [customerDeliveryProvider, setCustomerDeliveryProvider] = useState(
        quote?.customerDeliveryProvider ?? quote?.deliveryProvider
    )
    const [customerDeliveryAccountNumber, setCustomerDeliveryAccountNumber] = useState(
        quote?.customerDeliveryAccountNumber ?? null
    )

    const userTimeZone = dayjs.tz.guess()

    const showShippingBreakdown =
        shippingOption === 1 && deliveryPricingMethod === DELIVERY_PRICING_METHODS.ChargeToOrder.value

    const isOrdered = [QUOTE_STATUS.Ordered].includes(quote?.status)

    const isInvoiced = [QUOTE_STATUS.Invoiced].includes(quote?.status)

    const isPending = [QUOTE_STATUS.PendingOrderConfirmation].includes(quote?.status)

    const isPaid = isOrdered && quote?.paymentStatus !== QUOTE_PAYMENT_STATUS.Unpaid

    const isDisabled = [QUOTE_STATUS.Lost, QUOTE_STATUS.Cancelled, QUOTE_STATUS.Rejected, QUOTE_STATUS.Voided].includes(
        quote?.status
    )

    const isReadOnly = isInvoiced || isPending || isPaid

    const DELIVERY_PRICING_METHODS_OPTIONS = Object.values(DELIVERY_PRICING_METHODS).filter((method) =>
        method.sources.includes(quote?.quoteSourceType)
    )

    const handleCalculateQuote = async () => {
        try {
            await calculateQuote({ organisationId, quoteId: quote?.id }).unwrap()
        } catch (_e) {
            const errorMessage = t('$t(An error occurred) calculating the $t(quote).')
            enqueueSnackbar(errorMessage, { variant: 'error' })
        }
    }

    const updateQuoteHandler = async (attribute, value) => {
        if (value === '' && !['customerDeliveryAccountNumber', 'customerDeliveryProvider'].includes(attribute)) return

        try {
            await updateQuote({
                organisationId,
                quoteId: quote?.id,
                quote: {
                    ...quote,
                    ...(['deliveryPricingMethod', 'deliveryOption'].includes(attribute) &&
                        quote.status !== QUOTE_STATUS.NotCalculated &&
                        !isOrdered && { status: QUOTE_STATUS.NotCalculated }),
                    [attribute]: value,
                },
            }).unwrap()

            if (['deliveryPricingMethod', 'deliveryOption'].includes(attribute)) {
                if (isOrdered && !isPaid) {
                    handleCalculateQuote()
                }
            }
        } catch (error) {
            enqueueSnackbar(t('Failed to update the quote'), {
                variant: 'error',
            })
        }
    }

    const debouncedUpdateQuoteHandler = useDebouncedCallback(updateQuoteHandler, 1000)

    const handleShippingOptionChange = (event) => {
        setShippingOption(event.target.value)
        updateQuoteHandler('deliveryOption', event.target.value)
    }

    const handleDeliveryPricingMethodChange = (event) => {
        if (quote.status !== QUOTE_STATUS.NotCalculated) {
            dispatch(setSelectedQuoteStatus(QUOTE_STATUS.NotCalculated))
        }
        setDeliveryPricingMethod(event.target.value)
        updateQuoteHandler('deliveryPricingMethod', event.target.value)
    }

    const handleRequireShippingDateChange = (date) => {
        setRequireShippingDate(date)
        updateQuoteHandler('requiredDateUtc', date?.utc().format() || null)
    }

    const handleDeliveryProviderChange = (event) => {
        setCustomerDeliveryProvider(event.target.value)
        debouncedUpdateQuoteHandler('customerDeliveryProvider', event.target.value)
    }

    const handleDeliveryProviderBlur = (event) => {
        if (event.target.value === '') {
            setCustomerDeliveryProvider(
                quote?.customerDeliveryProvider || organisation?.internalPreferredDeliveryProvider
            )
        }
    }

    const handleDeliveryAccountNumberChange = (event) => {
        setCustomerDeliveryAccountNumber(event.target.value)
        debouncedUpdateQuoteHandler('customerDeliveryAccountNumber', event.target.value)
    }

    return (
        <TbxLocalizationProvider>
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                mb={3}
            >
                <Typography variant="strong2">{t('Shipping and delivery options')}</Typography>

                {showShippingBreakdown ? <QuoteShippingBreakdown quote={quote} /> : null}
            </Box>
            <Box
                alignItems="flex-start"
                alignSelf="stretch"
                display="flex"
                flexWrap="nowrap"
                gap={3}
                justifyContent="flex-start"
            >
                <TbxTooltip
                    title={
                        isReadOnly
                            ? t(
                                  'Shipping option cannot be changed after the quote is paid or invoiced or if is pending order confirmation'
                              )
                            : ''
                    }
                    arrow
                >
                    <FormControl
                        disabled={isDisabled || isReadOnly}
                        sx={[classes.shippingInputs, classes.disabledInput]}
                    >
                        <InputLabel
                            color="secondary"
                            id="select-shipping-option-label"
                            shrink={true}
                        >
                            {t('Shipping option')}
                        </InputLabel>
                        <Select
                            color="secondary"
                            disableUnderline={isDisabled || isReadOnly}
                            IconComponent={() => (isDisabled || isReadOnly ? null : <ArrowDropDown color="action" />)}
                            id="select-shipping-option"
                            inputProps={{
                                'data-testid': 'quote-shipping-option-input',
                            }}
                            label={t('Shipping option')}
                            labelId="select-shipping-option-label"
                            value={shippingOption ?? ''}
                            variant="standard"
                            onChange={handleShippingOptionChange}
                        >
                            {SHIPPING_OPTIONS.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {t(option.label)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </TbxTooltip>

                {showShipping && shippingOption === 1 ? (
                    <TbxTooltip
                        title={
                            isReadOnly
                                ? t(
                                      'Delivery pricing method cannot be changed after the quote is paid or invoiced or if is pending order confirmation'
                                  )
                                : ''
                        }
                        arrow
                    >
                        <FormControl
                            disabled={isDisabled || isReadOnly}
                            sx={[classes.shippingInputs, classes.disabledInput]}
                            fullWidth
                        >
                            <InputLabel
                                color="secondary"
                                id="select-delivery-pricing-method-label"
                                shrink={true}
                            >
                                {t('Delivery pricing method')}
                            </InputLabel>
                            <Select
                                color="secondary"
                                disableUnderline={isDisabled || isReadOnly}
                                IconComponent={() =>
                                    isDisabled || isReadOnly ? null : <ArrowDropDown color="action" />
                                }
                                id="select-delivery-pricing-method"
                                inputProps={{
                                    'data-testid': 'quote-delivery-pricing-method-input',
                                }}
                                label={t('Delivery pricing method')}
                                labelId="select-delivery-pricing-method-label"
                                value={deliveryPricingMethod ?? ''}
                                variant="standard"
                                onChange={handleDeliveryPricingMethodChange}
                            >
                                {DELIVERY_PRICING_METHODS_OPTIONS.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {t(option.shortLabel)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </TbxTooltip>
                ) : null}

                {showShipping && shippingOption === 1 && deliveryPricingMethod === 'PayUponDelivery' ? (
                    <>
                        <TextField
                            color="secondary"
                            disabled={isDisabled}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                'data-testid': 'quote-customer-delivery-provider-input',
                            }}
                            InputProps={{
                                disableUnderline: isDisabled,
                            }}
                            label={t('Delivery provider')}
                            placeholder={t('To be confirmed')}
                            sx={[classes.disabledInput, classes.shippingInputs]}
                            value={customerDeliveryProvider}
                            onBlur={handleDeliveryProviderBlur}
                            onChange={handleDeliveryProviderChange}
                        />

                        <TextField
                            color="secondary"
                            disabled={isDisabled}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                'data-testid': 'quote-customer-delivery-account-number-input',
                            }}
                            InputProps={{
                                disableUnderline: isDisabled,
                            }}
                            label={t('Delivery account number')}
                            placeholder={t('N/A')}
                            sx={[classes.disabledInput, classes.shippingInputs]}
                            value={customerDeliveryAccountNumber}
                            onChange={handleDeliveryAccountNumberChange}
                        />
                    </>
                ) : null}

                <DatePicker
                    closeOnSelect={false}
                    disabled={isDisabled}
                    disableOpenPicker={isDisabled}
                    format="DD-MMM-YYYY"
                    label={t('Required shipping date')}
                    slotProps={{
                        textField: {
                            sx: classes.disabledInput,
                            color: 'secondary',
                            placeholder: t('Select a date'),

                            inputProps: {
                                'data-testid': 'quote-require-shipping-date-input',
                            },
                            InputProps: {
                                disableUnderline: isDisabled,
                            },
                            InputLabelProps: {
                                shrink: true,
                            },
                        },
                        field: {
                            readOnly: true,
                        },
                        actionBar: {
                            actions: ['clear', 'cancel', 'accept'],
                            disableSpacing: true,
                        },
                    }}
                    timezone={userTimeZone}
                    value={requireShippingDate ? dayjs(requireShippingDate) : null}
                    disablePast
                    onAccept={handleRequireShippingDateChange}
                />
            </Box>
        </TbxLocalizationProvider>
    )
}

QuoteDetailsOptions.propTypes = {
    quote: PropTypes.object,
}

export default QuoteDetailsOptions
