import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Link, Typography } from '@mui/material'

import { selectOrganisation } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'
import { getProductDetails, LIBELLULA_PRICING_REDIRECT_URL, TOOLBOX_PRICING_REDIRECT_URL } from '@/common/utils'

const ActivateSubscriptionBar = () => {
    const { t } = useTranslation()

    const organisation = useSelector(selectOrganisation)
    const { showLibellulaWhitelabelling } = useToolBoxTreatments()

    return (
        <Box
            alignItems="center"
            data-testid="activate-subscription-bar"
            display="flex"
            gap={2}
        >
            <Typography
                color="error"
                data-testid="activate-subscription-bar-title"
                variant="body1"
            >
                {t("You're using a trial") +
                    '. ' +
                    t('To continue using {{title}} after the trial period', getProductDetails())}{' '}
                <Link
                    color="secondary"
                    data-testid="activate-subscription-bar-link"
                    href={
                        showLibellulaWhitelabelling
                            ? LIBELLULA_PRICING_REDIRECT_URL
                            : `${TOOLBOX_PRICING_REDIRECT_URL}${organisation?.paymentGatewayCustomerId}`
                    }
                    target="_blank"
                >
                    {t('request pricing')}
                </Link>
            </Typography>
        </Box>
    )
}

export default ActivateSubscriptionBar
