import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { selectResolvedPlanMetadata } from '@/app/slices/organisationSlice'
import { useToolBoxTreatments } from '@/common/hooks'

import ParagonConnectIntegration from './ParagonConnectIntegration'

const classes = {
    cardRoot: {
        width: 576,
    },
    inputsContainer: {
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
    },
}
const Integrations = () => {
    const { t } = useTranslation()

    const { showLibellulaWhitelabelling, showQuickBooks, showXero } = useToolBoxTreatments()

    const resolvedPlanMetadata = useSelector(selectResolvedPlanMetadata)

    return (
        <Grid
            px={2}
            spacing={3}
            container
        >
            <Grid xs={12}>
                <Typography
                    data-testid="organisation-pricing-setting"
                    id="integrations"
                    sx={classes.title}
                    variant="h6"
                >
                    {t('Integrations')}
                </Typography>
                <Typography variant="small">
                    {t('Connect your ToolBox account to the other systems that help run your business.')}
                </Typography>
            </Grid>

            <Grid xs={12}>
                <Box sx={classes.inputsContainer}>
                    {showQuickBooks && !showLibellulaWhitelabelling ? (
                        <ParagonConnectIntegration
                            disabled={!resolvedPlanMetadata.quickbooks}
                            type="quickbooks"
                        />
                    ) : null}
                    {showXero && !showLibellulaWhitelabelling ? (
                        <ParagonConnectIntegration
                            disabled={!resolvedPlanMetadata.xero}
                            type="xero"
                        />
                    ) : null}
                </Box>
            </Grid>
        </Grid>
    )
}

export default Integrations
