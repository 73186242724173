import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'

import { selectLocale } from '@/app/slices/appSlice'
import { selectOrganisation } from '@/app/slices/organisationSlice'
import { DateTime } from '@/common/components'

const TrialWithSubscriptionBar = () => {
    const { t } = useTranslation()

    const organisation = useSelector(selectOrganisation)
    const locale = useSelector(selectLocale)

    const { endDateUtc } = organisation?.trial ?? new Date()

    return (
        <Box
            alignItems="center"
            data-testid="activate-subscription-bar"
            display="flex"
            gap={2}
        >
            <Typography
                color="secondary"
                data-testid="activate-subscription-bar-title"
                variant="body1"
            >
                {t('Access to trial features ends on')}{' '}
                <DateTime
                    format="DD-MMM-YYYY"
                    locale={locale}
                >
                    {endDateUtc}
                </DateTime>
                .{' '}
            </Typography>
        </Box>
    )
}

export default TrialWithSubscriptionBar
